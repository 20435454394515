import React, { useState } from "react";
import { PlotlyDataset } from "./models";
import Plot from "react-plotly.js";

interface ChartProps {
  dataset: PlotlyDataset[];
  onUpdate?: (startDate: string, endDate: string) => void;
  hideLedgend?: boolean;
}

const selectorOptions = {
  buttons: [
    {
      step: "month",
      stepmode: "backward",
      count: 1,
      label: "1m",
    },
    {
      step: "month",
      stepmode: "backward",
      count: 6,
      label: "6m",
    },
    {
      step: "year",
      stepmode: "todate",
      count: 1,
      label: "YTD",
    },
    {
      step: "year",
      stepmode: "backward",
      count: 1,
      label: "1y",
    },
    {
      step: "all",
    },
  ],
};

const LineChart: React.FunctionComponent<ChartProps> = ({
  dataset,
  onUpdate,
  hideLedgend,
}) => {
  const [pristine, setPristine] = useState<boolean>(true);

  const [state, setState] = useState<any>({
    layout: {
      title: "",
      yaxis: {
        showspikes: true,
        spikethickness: 1,
        spikecolor: "black",
        spikedash: "dot",
      },
      xaxis: {
        rangeselector: selectorOptions,
      },
      showlegend: !hideLedgend,
      legend: { orientation: "w" },
    },
    frames: [],
    config: {},
  });

  return (
    <Plot
      data={dataset}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
      layout={state.layout}
      frames={state.frames}
      config={state.config}
      onUpdate={setState}
      onRelayout={() => {
        if (pristine) {
          setPristine(false);
          return;
        }
        const startDate = state.layout.xaxis.range[0].slice(0, 10);
        const endDate = state.layout.xaxis.range[1].slice(0, 10);
        onUpdate && onUpdate(startDate, endDate);
      }}
    />
  );
};

const StackChart: React.FunctionComponent<ChartProps> = ({
  dataset,
  onUpdate,
  hideLedgend,
}) => {
  const [pristine, setPristine] = useState<boolean>(true);

  const [state, setState] = useState<any>({
    layout: {
      barmode: "stack",
      bargap: 0,
      title: "",
      yaxis: {
        showspikes: true,
        spikethickness: 1,
        spikecolor: "black",
        spikedash: "dot",
      },
      xaxis: {
        rangeselector: selectorOptions,
      },
      showlegend: !hideLedgend,
      legend: { orientation: "w" },
    },
    frames: [],
    config: {},
  });

  return (
    <Plot
      data={dataset}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
      layout={state.layout}
      frames={state.frames}
      config={state.config}
      onUpdate={setState}
      onRelayout={() => {
        if (pristine) {
          setPristine(false);
          return;
        }
        const startDate = state.layout.xaxis.range[0].slice(0, 10);
        const endDate = state.layout.xaxis.range[1].slice(0, 10);
        onUpdate && onUpdate(startDate, endDate);
      }}
    />
  );
};

const e = {
  StackChart,
  LineChart,
};

export default e;
