export const formatDate = (ts: Date): string => {
  const m = (Number(ts.getMonth()) + Number(1)).toString().padStart(2, "0");
  const d = ts.getDate().toString().padStart(2, "0");
  return `${ts.getFullYear()}-${m}-${d}`;
};

export const copyDate = (ts: Date): Date => {
  const d = new Date();
  d.setDate(ts.getDate());
  d.setMonth(ts.getMonth());
  d.setFullYear(ts.getFullYear());
  return d;
};

export const getMonthEnd = (ts: Date): Date => {
  const endDate = copyDate(ts);
  endDate.setFullYear(endDate.getFullYear());
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(endDate.getDate() - 1);
  return endDate;
};

const audFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AUD",
  currencyDisplay: "narrowSymbol",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatAUD = audFormatter.format;

export const formatDecimal = (n: number): string =>
  formatAUD(n).replace("$", "");
