import React, { useCallback, useState } from "react";
import { Frame, TopBar, Navigation } from "@shopify/polaris";
import {
  LogOutMinor,
  HomeMajor,
  ListMajor,
  VocabularyMajor,
} from "@shopify/polaris-icons";
import { Account } from "./models";
import api from "./api";

enum NavState {
  Summary,
  Accounts,
  Transactions,
}

interface Props {
  children?: React.ReactNode
  setNavState: React.Dispatch<React.SetStateAction<NavState | string>>;
  accounts: Account[];
}

const NavFrame: React.FC<Props> = ({ setNavState, accounts, children }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const handleSearchChange = useCallback((value) => {
    console.log("search val " + value);
    setSearchValue(value);
  }, []);

  const fetchVanguard = api.useFetchVanguard();
  const syncSpaceship = api.useSyncSpaceship();
  const syncBtcMarkets = api.useSyncBtcMarkets();
  const syncYahooPrices = api.useSyncYahooPrices();
  const refreshViews = api.useRefreshViews();
  const vanguardOTP = api.useVanguardOTP();

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [{ content: "Log out", icon: LogOutMinor }],
        },
      ]}
      name="Jurry Lambchop"
      detail="Lambchop"
      initials="J"
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder="Search"
      showFocusBorder
    />
  );

  const nav = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: "Summary",
            icon: HomeMajor,
            onClick: () => {
              setNavState(NavState.Summary);
            },
          },
          {
            label: "Accounts",
            icon: VocabularyMajor,
            //badge: "15",
            onClick: () => {
              setNavState(NavState.Accounts);
            },
          },
          {
            label: "Transactions",
            icon: ListMajor,
            onClick: () => {
              setNavState(NavState.Transactions);
            },
          },
        ]}
      />
      {accounts && (
        <Navigation.Section
          title="Accounts"
          items={accounts
            .filter((account) => account.currentBalance !== 0)
            .map((account) => ({
              onClick: () => {
                setNavState(account.accountID);
              },
              label: account.accountName,
              icon: VocabularyMajor,
            }))}
        />
      )}

      {accounts && (
        <Navigation.Section
          title="Inactive accounts"
          items={accounts
            .filter((account) => account.currentBalance === 0)
            .map((account) => ({
              onClick: () => {
                setNavState(account.accountID);
              },
              label: account.accountName,
              icon: VocabularyMajor,
            }))}
        />
      )}

      <Navigation.Section
        title="Actions"
        items={[
          {
            label: "Sync Vanguard",
            onClick: async () => {
              let token: string;
              try {
                token = await fetchVanguard.mutateAsync();
              } catch (err) {
                window.alert(`getting token err: ${err}`);
                return;
              }
              if (token === "") {
                window.alert("Vanguard done!");
                return;
              }

              const otp = window.prompt("otp please") as string;
              try {
                await vanguardOTP.mutateAsync({ token, otp });
              } catch (err) {
                window.alert(`vanguard OTP err: ${err}`);
                return;
              }
              window.alert("Vanguard done!");
            },
          },
          {
            label: "Sync Spaceship",
            onClick: async () => {
              let token: string;
              try {
                token = await syncSpaceship.mutateAsync({});
              } catch (err) {
                window.alert(`getting token err: ${err}`);
                return;
              }
              if (token === "") {
                window.alert("Spaceship done!");
                return;
              }

              const otp = window.prompt("otp please") as string;
              try {
                await syncSpaceship.mutateAsync({
                  mfa_challenge_id: token,
                  mfa_challenge_response: otp
                });
              } catch (err) {
                window.alert(`spaceship OTP err: ${err}`);
                return;
              }
              window.alert("Spaceship done!");
            },
          },
          {
            label: "Sync BTC Markets",
            onClick: async () => {
              try {
                await syncBtcMarkets.mutateAsync();
              } catch (err) {
                window.alert(`sync btc markets err: ${err}`);
                return;
              }
              window.alert("BTC Markets done!");
            },
          },
          {
            label: "Sync Yahoo prices",
            onClick: async () => {
              try {
                await syncYahooPrices.mutateAsync();
              } catch (err) {
                window.alert(`sync yahoo prices err: ${err}`);
                return;
              }
              window.alert("Yahoo prices done!");
            },
          },
          {
            label: "Refresh views",
            onClick: async () => {
              try {
                await refreshViews.mutateAsync();
              } catch (err) {
                window.alert(`refresh views err: ${err}`);
                return;
              }
              window.alert("Refreshing views done!");
            },
          },
        ]}
      />
    </Navigation>
  );

  const topBar = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchField={searchFieldMarkup}
    />
  );

  return (
    <Frame topBar={topBar} navigation={nav}>
      {children}
    </Frame>
  );
};

export { NavFrame, NavState };
