import { useState, Fragment, useCallback, useMemo } from "react";
import api from "./api";
import chart from "./chart";
import { NavFrame, NavState } from "./nav";
import Transactions from "./transactions";
import AccountsTable from "./accounts";
import { PlotlyDataset } from "./models";
import {
  formatDate,
  formatAUD,
  formatDecimal,
  copyDate,
  getMonthEnd,
} from "./utils";
import {
  Page,
  Card,
  DataTable,
  Layout,
  Stack,
  SkeletonBodyText,
  ButtonGroup,
  Button,
  Select,
  TableData,
  SortDirection,
} from "@shopify/polaris";

const monthNames: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ChangeRows = ({
  current,
  prev,
  prefix,
}: {
  current: number;
  prev: number;
  prefix: string;
}) => {
  const diff = current - prev;
  return (
    <Fragment>
      {prefix} {diff > 0 ? "🔼" : "🔽"}
      <p style={{ color: diff > 0 ? "green" : "red" }}>{formatDecimal(diff)}</p>
      <p style={{ color: diff > 0 ? "green" : "red" }}>
        {formatDecimal((100 * diff) / prev)}%
      </p>
    </Fragment>
  );
};

const getChangeOverTimeSummary = (name: string, ts?: PlotlyDataset[]) => {
  if (!ts) {
    return null;
  }

  const tl = ts.filter((t) => t.name === name);
  if (tl.length !== 1) {
    return null;
  }
  const t = tl[0];

  const lastDP = t.y[t.y.length - 1];

  const sevenDay = t.y[t.y.length - 8];
  const thirtyDay = t.y[t.y.length - 31];
  const sixMonth = t.y[t.y.length - 181];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <span style={{ width: "100px" }}>
        <ChangeRows prefix="6m" current={lastDP} prev={sixMonth} />
      </span>
      <span style={{ width: "100px" }}>
        <ChangeRows prefix="30d" current={lastDP} prev={thirtyDay} />
      </span>
      <span style={{ width: "100px" }}>
        <ChangeRows prefix="7d" current={lastDP} prev={sevenDay} />
      </span>
    </div>
  );
};

const Summary = () => {
  const timeseriesStartDate = new Date();
  timeseriesStartDate.setFullYear(timeseriesStartDate.getFullYear() - 3);
  const timeseriesQuery = api.useGetTimeseries(timeseriesStartDate);
  const accountsQuery = api.useGetAccounts();

  const netChange = getChangeOverTimeSummary(
    "Net",
    timeseriesQuery.data?.line.summaries
  );
  const stockChange = getChangeOverTimeSummary(
    "Stocks",
    timeseriesQuery.data?.line.summaries
  );
  const cryptoChange = getChangeOverTimeSummary(
    "Crypto",
    timeseriesQuery.data?.line.summaries
  );
  const cashChange = getChangeOverTimeSummary(
    "Cash",
    timeseriesQuery.data?.line.summaries
  );

  const [chartType, setChartType] = useState("line");

  return (
    <Fragment>
      <Layout.Section>
        <Card title="Timeseries" sectioned>
          {timeseriesQuery.isLoading && <SkeletonBodyText />}

          {timeseriesQuery.isError && <p>{timeseriesQuery.error.message}</p>}

          {timeseriesQuery.data?.line.summaries && (
            <div>
              <Card.Section title="Summaries">
                <ButtonGroup segmented>
                  <Button
                    pressed={chartType === "stack"}
                    onClick={() => setChartType("stack")}
                  >
                    Stack
                  </Button>
                  <Button
                    pressed={chartType === "line"}
                    onClick={() => setChartType("line")}
                  >
                    Line
                  </Button>
                </ButtonGroup>
                {chartType === "line" && (
                  <chart.LineChart
                    dataset={timeseriesQuery.data.line.summaries}
                  />
                )}
                {chartType === "stack" && (
                  <chart.StackChart
                    dataset={timeseriesQuery.data.stack.summaries}
                  />
                )}
              </Card.Section>
            </div>
          )}
        </Card>
      </Layout.Section>

      <Layout.Section>
        <Stack distribution="fill">
          <Card title="Summary" sectioned>
            {accountsQuery.isLoading && <SkeletonBodyText />}

            {accountsQuery.isError && <p>{accountsQuery.error.message}</p>}

            {accountsQuery.isSuccess && accountsQuery.data && (
              <Card.Section title="Totals Breakdown">
                <DataTable
                  columnContentTypes={["text", "numeric", "numeric", "numeric"]}
                  headings={["Asset category", "Weighting", "Change", "Value"]}
                  totalsName={{
                    singular: "Net Worth",
                    plural: "New Worth",
                  }}
                  showTotalsInFooter={true}
                  totals={[
                    "",
                    "",
                    netChange,
                    formatAUD(accountsQuery.data.totals.netWorth),
                  ]}
                  rows={[
                    [
                      "Cash",
                      formatDecimal(accountsQuery.data.totals.cashWeighting),
                      cashChange,
                      formatAUD(accountsQuery.data.totals.cash),
                    ],
                    [
                      "Stocks",
                      formatDecimal(accountsQuery.data.totals.stocksWeighting),
                      stockChange,
                      formatAUD(accountsQuery.data.totals.stocks),
                    ],
                    [
                      "Cryptocurrencies",
                      formatDecimal(
                        accountsQuery.data.totals.cryptocurrenciesWeighting
                      ),
                      cryptoChange,
                      formatAUD(accountsQuery.data.totals.cryptocurrencies),
                    ],
                  ]}
                />
              </Card.Section>
            )}
          </Card>

          <Card title="Accounts" sectioned>
            {accountsQuery.isLoading && <SkeletonBodyText />}

            {accountsQuery.isError && <p>{accountsQuery.error.message}</p>}

            {accountsQuery.isSuccess && accountsQuery.data && (
              <AccountsTable accountSummary={accountsQuery.data} />
            )}
          </Card>
        </Stack>
      </Layout.Section>

      <MonthlyTransactions />
    </Fragment>
  );
};

const MonthlyTransactions = () => {
  const options: { label: string; value: string }[] = [];
  const optionIndexToDate = {};

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 540);
  minDate.setDate(1);

  const d = new Date();
  d.setDate(1);
  var i = 0;
  while (d > minDate) {
    options.push({
      label: `${monthNames[d.getMonth()]} ${d.getFullYear()}`,
      value: i.toString(),
    });
    optionIndexToDate[i.toString()] = copyDate(d);

    d.setMonth(d.getMonth() - 1);
    i++;
  }

  const [indexSelected, setIndexSelected] = useState(0);

  const startDate = optionIndexToDate[indexSelected];
  const endDate = getMonthEnd(optionIndexToDate[indexSelected]);

  const transactions = api.useGetTransactions({
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });

  const handleSelectChange = useCallback(
    (value) => setIndexSelected(value),
    []
  );

  const totalsRow: TableData[] =
    transactions.isSuccess && transactions.data
      ? [
        "Total",
        transactions.data.credits ? transactions.data.credits : "❌",
        transactions.data.debits ? transactions.data.debits : "❌",
        transactions.data.creditAmount
          ? formatAUD(transactions.data.creditAmount)
          : "❌",
        transactions.data.debitAmount
          ? formatAUD(transactions.data.debitAmount)
          : "❌",
        transactions.data.amountSum
          ? formatAUD(transactions.data.amountSum)
          : "❌",
      ]
      : [];

  const limit = 8;

  const initialSortIndex = 5;
  const [sortIndex, setSortIndex] = useState<number>(initialSortIndex);
  const [sortDirection, setSortDirection] =
    useState<SortDirection>("ascending");

  const rows: TableData[][] = useMemo(() => {
    if (
      !transactions.isSuccess ||
      !transactions.data ||
      !transactions.data.categoryBreakdowns
    ) {
      return [];
    }

    const rows: TableData[][] = transactions.data.categoryBreakdowns
      .filter((b) => {
        /*jslint eqeq: true*/
        if (
          b.category.id === "transfers" &&
          b.creditAmount.toString() === (-b.debitAmount).toString()
        ) {
          return false;
        }
        return true;
      })
      .map((b) => {
        return [
          b.category.name,
          b.credits,
          b.debits,
          b.creditAmount,
          b.debitAmount,
          b.totalAmount,
        ];
      });

    var [
      remainderCredits,
      remainderDebits,
      remainderCreditAmount,
      remainderDebitAmount,
      remainderTotalAmount,
    ] = [0, 0, 0, 0, 0];

    const sortedRows = [...rows]
      .sort((rowA, rowB) => {
        const amountA = Number(rowA[sortIndex]);
        const amountB = Number(rowB[sortIndex]);

        return sortDirection === "descending"
          ? amountB - amountA
          : amountA - amountB;
      })
      .filter((r, i) => {
        if (i >= limit) {
          remainderCredits += Number(r[1]);
          remainderDebits += Number(r[2]);
          remainderCreditAmount += Number(r[3]);
          remainderDebitAmount += Number(r[4]);
          remainderTotalAmount += Number(r[5]);
        }

        return i < limit;
      });

    sortedRows.push([
      "Everything else",
      remainderCredits,
      remainderDebits,
      remainderCreditAmount,
      remainderDebitAmount,
      remainderTotalAmount,
    ]);

    return sortedRows.map((r) => {
      return [
        r[0],
        r[1],
        r[2],
        formatAUD(Number(r[3])),
        formatAUD(Number(r[4])),
        formatAUD(Number(r[5])),
      ];
    });
  }, [transactions.isSuccess, transactions.data, sortDirection, sortIndex]);

  return (
    <Layout.Section>
      <Card title="Transactions" sectioned>
        <Card.Section title="Month">
          <Select
            label=""
            options={options}
            onChange={handleSelectChange}
            value={indexSelected.toString()}
          />
        </Card.Section>
        {transactions.isLoading && <SkeletonBodyText />}

        {transactions.isError && <p>{transactions.error.message}</p>}

        {transactions.isSuccess && transactions.data && (
          <Card.Section title="">
            <DataTable
              totalsName={{
                singular: "Total",
                plural: "Total",
              }}
              showTotalsInFooter={true}
              totals={totalsRow}
              columnContentTypes={[
                "text",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
              ]}
              headings={[
                "Category",
                "Credits",
                "Debits",
                "Credit $",
                "Debit $",
                "Total",
              ]}
              rows={rows}
              sortable={[false, true, true, true, true, true]}
              defaultSortDirection="descending"
              initialSortColumnIndex={initialSortIndex}
              onSort={(headingIndex, direction) => {
                setSortDirection(direction);
                setSortIndex(headingIndex);
              }}
            />
          </Card.Section>
        )}
      </Card>

      <Transactions
        startDate={formatDate(startDate)}
        endDate={formatDate(endDate)}
      />
    </Layout.Section>
  );
};

const Accounts = () => {
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 3);
  const [timeseriesStartDate] = useState<Date>(startDate);

  const timeseriesQuery = api.useGetTimeseries(timeseriesStartDate);
  const accountsQuery = api.useGetAccounts();
  const accountSummary = accountsQuery.data;

  const [stocksEnabled, setStocksEnabled] = useState<boolean>(true);
  const [cashEnabled, setCashEnabled] = useState<boolean>(true);
  const [cryptoEnabled, setCryptoEnabled] = useState<boolean>(true);

  return (
    <Fragment>
      <Layout.Section>
        <Card title="Accounts timeseries" sectioned>
          {timeseriesQuery.isLoading && <SkeletonBodyText />}

          {timeseriesQuery.isError && <p>{timeseriesQuery.error.message}</p>}

          {timeseriesQuery.data?.line.accounts &&
            <Card.Section title="Accounts">
              <ButtonGroup segmented>
                <Button
                  pressed={cashEnabled}
                  onClick={() => setCashEnabled(!cashEnabled)}
                >
                  Cash
                </Button>
                <Button
                  pressed={stocksEnabled}
                  onClick={() => setStocksEnabled(!stocksEnabled)}
                >
                  Stocks
                </Button>
                <Button
                  pressed={cryptoEnabled}
                  onClick={() => setCryptoEnabled(!cryptoEnabled)}
                >
                  Crypto
                </Button>
              </ButtonGroup>
              <chart.LineChart dataset={timeseriesQuery.data.line.accounts.reduce(
                (previousValue: PlotlyDataset[], currentValue) => {
                  if (currentValue.assetType === 'CASH' && cashEnabled) {
                    return previousValue.concat(currentValue.datasets);
                  }
                  if (currentValue.assetType === 'STOCK' && stocksEnabled) {
                    return previousValue.concat(currentValue.datasets);
                  }
                  if (currentValue.assetType === 'CRYPTOCURRENCY' && cryptoEnabled) {
                    return previousValue.concat(currentValue.datasets);
                  }
                  return previousValue;
                },
                [],
              )} />
            </Card.Section>
          }
        </Card>
      </Layout.Section>

      <Layout.Section>
        {accountsQuery.isError && (
          <Card title="Accounts error" sectioned>
            <p>{accountsQuery.error.message}</p>
          </Card>
        )}

        {!accountsQuery.isError && (
          <Card title="Accounts" sectioned>
            {accountsQuery.isLoading ? (
              <SkeletonBodyText />
            ) : (
              accountSummary && (
                <AccountsTable accountSummary={accountSummary} />
              )
            )}
          </Card>
        )}
      </Layout.Section>
    </Fragment>
  );
};

const Home = () => {
  const [navState, setNavState] = useState<NavState | string>(NavState.Summary);
  const accountsQuery = api.useGetAccounts();

  return (
    <NavFrame
      setNavState={setNavState}
      accounts={accountsQuery.data?.accounts || []}
    >
      <Page fullWidth>
        <Layout>
          {navState === NavState.Summary && <Summary />}

          {navState === NavState.Accounts && <Accounts />}

          {navState === NavState.Transactions && (
            <Layout.Section>
              <Transactions />
            </Layout.Section>
          )}

          {typeof navState === "string" && (
            <Layout.Section>
              <Transactions
                accountIDs={accountsQuery.data?.accounts
                  .filter((acc) => {
                    return acc.accountID === navState;
                  })
                  .map((acc) => acc.accountID)}
              />
            </Layout.Section>
          )}
        </Layout>
      </Page>
    </NavFrame>
  );
};

export default Home;
