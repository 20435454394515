import React from "react";
import { Card, DataTable } from "@shopify/polaris";
import { AccountSummary } from "./models";
import { formatAUD, formatDecimal } from "./utils";

interface AccountsTableProps {
  accountSummary: AccountSummary;
}

const AccountsTable: React.FC<AccountsTableProps> = ({ accountSummary }) => {
  const instruments = accountSummary.instruments.filter((instrument) => {
    return instrument.value !== 0;
  });
  return (
    <div>
      <Card.Section title="Cash accounts">
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
          ]}
          headings={[
            "Name",
            "Institution",
            "Net weight",
            "Cash weight",
            "Current",
            "Available",
            "Transactions",
          ]}
          totalsName={{
            singular: "Cash balance",
            plural: "Cash balance",
          }}
          totals={[
            "",
            "",
            "",
            "",
            formatAUD(
              accountSummary.accounts
                .map((acc) => acc.currentBalance)
                .reduce((curr: number, prev: number) => {
                  return curr + prev;
                })
            ),
            formatAUD(
              accountSummary.accounts
                .map((acc) => acc.availableBalance)
                .reduce((curr: number, prev: number) => {
                  return curr + prev;
                })
            ),
            "",
          ]}
          rows={accountSummary.accounts
            .filter((acc) => acc.currentBalance !== 0.0)
            .map((acc) => [
              acc.accountName,
              acc.bank,
              acc.netWeight.toFixed(2),
              acc.typeWeight.toFixed(2),
              formatAUD(acc.currentBalance),
              formatAUD(acc.availableBalance),
              acc.transactionSum === acc.currentBalance
                ? "✅"
                : `🚩 ${formatAUD(acc.transactionSum)}`,
            ])}
        />
      </Card.Section>
      <Card.Section title="Instruments">
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
          ]}
          headings={[
            "Name",
            "Institution",
            "Asset Type",
            "Last Close Date",
            "Net weight",
            "Weight",
            "Realised",
            "Unrealised",
            "Unit Price",
            "Units held",
            "Value",
          ]}
          totalsName={{
            singular: "Total value",
            plural: "Total value",
          }}
          totals={[
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            accountSummary.totals.cryptocurrencies.toFixed(2),
          ]}
          rows={instruments.map((i) => [
            i.name,
            i.institution,
            i.assetType === "CRYPTOCURRENCY" ? "Cryto" : "Stocks",
            i.lastCloseDate,
            formatDecimal(i.netWeight),
            formatDecimal(i.typeWeight),
            formatAUD(i.realisedGains),
            formatAUD(i.unrealisedGains),
            formatAUD(i.unitPrice),
            formatDecimal(i.units),
            formatAUD(i.value),
          ])}
        />
      </Card.Section>
    </div>
  );
};

export default AccountsTable;
