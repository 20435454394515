import React, { Fragment } from "react";
import { DataTable, Autocomplete } from "@shopify/polaris";
import api from "./api";
import { Transaction } from "./models";
import { Card, SkeletonBodyText } from "@shopify/polaris";
import { TransactionsQuery } from "./models";
import { formatAUD } from "./utils";

interface TransactionsTableProps {
  transactions: Transaction[];
}

const descriptionDisplay = (tx: Transaction): string => {
  let d: string = "";
  if (tx.metadata) {
    if (tx.metadata.description !== "") {
      d += tx.metadata.description;
    }
    if (tx.metadata.message !== "") {
      d += ` - ${tx.metadata.message}`;
    }
  }

  if (d === "") {
    return tx.description.substring(0, 30);
  }

  return d;
};

const CategoryAutocomplete: React.FC<{
  storedCategory?: string;
  onSelect: (value: string) => void;
}> = ({ storedCategory, onSelect }) => {
  const categories = api.useGetCategories();

  const deselectedOptions = React.useMemo(() => {
    return (
      categories.data?.categories.map((c) => {
        return { value: c.id, label: c.name };
      }) || []
    );
  }, [categories.data]);

  const [inputValue, setInputValue] = React.useState("");
  const [editable, setEditable] = React.useState(false);
  const [options, setOptions] = React.useState(deselectedOptions);

  const updateText = React.useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions?.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  React.useEffect(() => {
    if (!storedCategory) {
      setEditable(true);
    }
  }, [storedCategory]);

  const updateSelection = React.useCallback(
    (selected) => {
      onSelect(selected[0]);
      setEditable(false);
    },
    [onSelect]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      value={inputValue}
      placeholder="Search"
      autoComplete="off"
    />
  );

  if (!editable) {
    return (
      <div onClick={() => setEditable(true)}>
        <p>{storedCategory}</p>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "250px",
        height: "10px",
        transform: "translate(0, -8px)",
      }}
    >
      <Autocomplete
        options={options}
        selected={[]}
        onSelect={updateSelection}
        textField={textField}
      />
    </div>
  );
};

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  transactions,
}) => {
  const updateMetadata = api.useUpdateTransactionMetadata();

  return (
    <DataTable
      columnContentTypes={["text", "numeric", "text", "text", "text"]}
      headings={["Date", "Amount", "Description", "Account", "Category"]}
      rows={transactions.map((tx) => {
        return [
          tx.accountName === "Up Account"
            ? tx.createdAt.toLocaleString("en-AU", {
                timeZone: "Australia/Sydney",
              })
            : tx.date,
          formatAUD(tx.amount),
          <div
            style={{ minWidth: "450px", maxWidth: "650px", overflow: "scroll" }}
          >
            <p>{descriptionDisplay(tx)}</p>
          </div>,
          tx.accountName,
          <CategoryAutocomplete
            storedCategory={tx.metadata?.category}
            onSelect={async (value: string) => {
              try {
                await updateMetadata.mutateAsync({
                  transactionID: tx.transactionID,
                  categoryID: value,
                });
              } catch (err) {
                window.alert(`updating transaction metadata err: ${err}`);
              }
            }}
          />,
        ];
      })}
    />
  );
};

const Transactions: React.FC<TransactionsQuery> = (query) => {
  const transactions = api.useGetTransactions(query);

  let title = "Transactions";

  if (query.startDate && query.endDate) {
    title += ` (${query.startDate} to ${query.endDate})`;
  }

  return (
    <Fragment>
      {transactions.isError && (
        <Card title="Error fetching transactions" sectioned>
          <p>{transactions.error.message}</p>
        </Card>
      )}

      {!transactions.isError && (
        <Card title={title} sectioned>
          {transactions.isLoading ? (
            <SkeletonBodyText />
          ) : (
            transactions.data && (
              <TransactionsTable transactions={transactions.data.list} />
            )
          )}
        </Card>
      )}
    </Fragment>
  );
};

export default Transactions;
